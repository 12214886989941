import React from 'react'
import {
  XYPlot,
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  GradientDefs,
  LineSeries,
  Hint,
} from 'react-vis'

import './LineSeries.scss'

export default class CustomLineSeries extends React.Component {
  state = {
    value: false,
  }
  render() {
    let { data, xTitle, xUnit, yTitle, yUnit } = this.props
    return (
      <div style={{ display: 'flex', height: 200, position: 'relative' }}>
        <FlexibleXYPlot
          color={'url(#elevationGradient)'}
          onMouseLeave={() => this.setState({ value: false })}
        >
          <GradientDefs>
            <linearGradient id="elevationGradient" x1="0" x2="0" y1="0" y2="1">
              <stop offset="15%" stopColor="blue" stopOpacity={0.8} />
              <stop offset="100%" stopColor="red" stopOpacity={0.8} />
            </linearGradient>
          </GradientDefs>

          <LineSeries
            onNearestXY={point => this.setState({ value: point })}
            data={data}
            color={'url(#elevationGradient)'}
            curve={'curveMonotoneX'}
          />
          {this.state.value && (
            <Hint value={this.state.value}>
              <div className="chart-popover">
                <div>
                  <b>{xTitle || 'x'}:</b>{' '}
                  {Number(this.state.value.x.toFixed(1))}
                  {xUnit}
                </div>
                <div>
                  <b>{yTitle || 'y'}:</b>{' '}
                  {Number(this.state.value.y.toFixed(1))}
                  {yUnit}
                </div>
              </div>
            </Hint>
          )}
        </FlexibleXYPlot>
      </div>
    )
  }
}
