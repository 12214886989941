import { css } from '@emotion/core'
import breaks from '../scss/mixins/_breakpoints.scss'
import varColors from '../scss/colors.scss'

export const colors = {
  themeAccent: '#7480f7',
  gray100: '#f8f9fa',
  gray300: '#dee2e6',
  gray500: '#adb5bd',
  gray600: '#868e96',
  brandDarker: '#221133',
  brandDark: '#56658d',
  brand: '#56578D',
  brandBright: '#e0d6eb',
  brandLight: '#f5f3f7',
  brandLighter: '#fbfafc',
  lightest: '#ffffff',
  darkest: '#4d4058',
  disabled: '#ced4da',
  textLight: '#7e718a',
  textLighter: '#aaaaaa',
  text: '#333333',
  lilac: `#8c65b3`,
  accent: `#ffb238`,
  white: '#fff',
  black: '#222',
}

export const spacing = {
  '3xs': 2,
  '2xs': 4,
  xs: 8,
  sm: 12,
  md: 16,
  lg: 24,
  xl: 32,
  '2xl': 40,
  '3xl': 48,
}

export const breakpoints = {
  smallPhone: '450px',
  phoneOnly: '599px',
  tabletPortrait: '600px',
  landScapeUp: '900px',
  desktopUp: '1200px',
  bigDesktopUp: '1800px',
}

export const radius = {
  default: 6,
  large: 50,
}

const defaultFontStack = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Open Sans',
  'Helvetica Neue',
  'sans-serif',
].join()

const monospaceFontStack = [
  `Space Mono`,
  `SFMono-Regular`,
  `Menlo`,
  `Monaco`,
  `Consolas`,
  `Liberation Mono`,
  `Courier New`,
  `monospace`,
].join()

export const fonts = {
  body: defaultFontStack,
  heading: defaultFontStack,
  monospace: monospaceFontStack,
}

export const dropdown = {
  container: css`
    background: ${colors.lightest};
    border: 1px solid ${colors.brandBright};
    border-radius: ${radius.default}px;
    border-top-right-radius: 0;
    box-shadow: 0 3px 8px ${colors.textLight}40;
    box-sizing: border-box;
    margin-top: ${spacing['2xs']}px;
    padding: ${spacing.sm}px;
    position: absolute;
    right: ${spacing.sm + spacing.xs}px;
    top: 100%;
    &::before,
    &::after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }
    &::before {
      top: -12px;
      right: -1px;
      border-width: 0 0 12px 12px;
      border-color: transparent transparent ${colors.brandBright} transparent;
    }
    &::after {
      top: -10px;
      right: 0;
      border-width: 0 0 10px 10px;
      border-color: transparent transparent ${colors.lightest} transparent;
    }
  `,
  heading: css`
    color: ${colors.brand};
    font-size: 0.75rem;
    font-weight: normal;
    margin: 0 0 ${spacing.sm}px;
  `,
  divider: css`
    background: ${colors.brandLight};
    height: 1px;
    margin-left: -${spacing.sm}px;
    margin-right: -${spacing.sm}px;
  `,
  item: css`
    color: #000;
    display: block;
    font-size: 0.875rem;
    margin-left: -${spacing.sm}px;
    margin-right: -${spacing.sm}px;
    padding: ${spacing.sm}px;
    &:hover {
      background-color: ${colors.brandLighter};
      color: ${colors.brand};
    }
  `,
}

export const input = {
  default: css`
    background-color: ${colors.lightest};
    border: 1px solid ${colors.brandBright} !important;
    border-radius: ${radius.default}px;
    box-sizing: border-box;
    color: ${colors.text};
    display: block;
    font-size: 15px;
    padding: ${spacing.xs}px ${spacing.sm}px;
  `,
  small: css`
    font-size: 12px;
    padding: ${spacing.xs}px;
  `,
  hover: css`
    box-shadow: 0 0 0 3px ${colors.brandBright} !important;
  `,
  focus: css`
    border-color: ${colors.lilac} !important;
    box-shadow: 0 0 0 3px ${colors.brandBright} !important;
    outline: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  `,
}

export const select = {
  default: css`
  appearance: none;
  background-image: url("data:image/svg+xml;charSet=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23${colors.lilac.substr(
    1
  )}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 8px 10px;
  padding-right: ${spacing.xl}px !important;
`,
  small: css`
    padding-right: ${spacing.xl}px !important;
  `,
}
